<template>
  <div class="video-detail">

    <img src="../../assets/sxImg/sx-1.png" style="width: 100%;display: block" alt="">

   <div class="play"  @click="playVideoOrPause">

     <img class="video-img" src="../../assets/sxImg/sx-2.png" style="width: 100%;display: block" alt="">
<!--     poster="../../assets/icon_poster.png"-->
<!--     poster="../../assets/icon_poster.png"-->
     <div class="video-play" >
       <video
         id="videoPlayer"
         :controlslist="false"
         :webkit-playsinline="false"
         x-webkit-airplay="true"
         playsinline="true"
         x5-video-player-type="h5"
         x5-video-orientation="h5"
         x5-video-player-fullscreen="true"
         poster="../../assets/icon_poster.png"
         preload="auto"
         loop
         style="width:100%;height:100%;object-fit: cover"

       >
         <source :src="data.url"/>
       </video>

         <img v-show="!data.isPlay" class="play-btn"  src="../../assets/home/icon_pause.png" alt="">

<!--       <img class="video-left-img" src="../../assets/sxImg/img-left-1.png" style="width: 100%;display: block" alt="">-->
<!--       <img class="video-right-img" src="../../assets/sxImg/img-right-1.png" style="width: 100%;display: block" alt="">-->
<!--       <img class="video-top-img" src="../../assets/sxImg/img-top-1.png" style="width: 100%;display: block" alt="">-->
<!--       <img class="video-bottom-img" src="../../assets/sxImg/img-bottom-1.png" style="width: 100%;display: block" alt="">-->

     </div>


   </div>

    <img  src="../../assets/sxImg/sx-3.png" style="width: 100%;display: block" alt="">



  </div>

</template>

<script>
import  Qrcode from 'qrcodejs2'
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import {clearToast, showFailToast} from "../../utils/util";

export default {
  name: "videoPlayDetail",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();


    const videoRef = ref(null);

    const data = reactive({
      isPlay : false,
      url:window.location.origin + '/sxcs.mov'
    })



    const playVideoOrPause = () => {

      console.log(document.getElementById('videoPlayer'))

      if(!data.isPlay){
        data.isPlay = true;
        document.getElementById('videoPlayer').play();
      }else{
        data.isPlay = false;
        document.getElementById('videoPlayer').pause();
      }


    }



    return {
      data,
      videoRef,
      playVideoOrPause

    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


